<template>
	<div>
		<!-- Toolbar -->
		<n-toolbar title="庫存" left-text="登出" @clickLeft="$router.push('/signin')" />
		<!-- Content -->
		<div class="bg-secondary h-screen overflow-auto p-3 relative text-white">
			<n-list-item class="text-lg" :start="`共有${inventory.length}筆`"></n-list-item>
			<!-- Cart list -->
			<div class="flex flex-col gap-5 pb-16">
				<n-button v-for="inventoryItem in inventory" :key="inventoryItem.id" color="light" class="active:bg-primary active:text-white w-full text-xl">
					<n-list-item :start="inventoryItem.product.name" :end="`${inventoryItem.quantity}${inventoryItem.product.defaultUnit}`" />
				</n-button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	computed: {
		...mapGetters(['inventory']),
	},
}
</script>
